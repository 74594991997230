/**
 * @file   ContactUs.js
 * @brief  page for reporting issues/giving feedback-->is looped with walletcollectormain@gmail.com  emailJS auto email
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */


import React, { useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './ContactUs.css';

const ContactUs = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.jsdelivr.net/npm/@emailjs/browser@4/dist/email.min.js';
    script.onload = () => {
      emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
    };
    document.body.appendChild(script);
  }, []);
  const [formType, setFormType] = useState(null); // "issue" or "feedback"
  const [formData, setFormData] = useState({
    subject: '',
    description: '',
    email: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_FEEDBACK_TEMPLATE,
        {
          from_name: formData.email || 'Anonymous',
          to_name: process.env.REACT_APP_EMAILJS_EMAIL_ACCOUNT,
          message: formData.description,
          reply_to: formData.email,
        }
      );
      setFormType('submitted');
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className="contact-us">
      <h1>Contact Us</h1>
      <div className="form-type-buttons">
        <button
          className={`issue-button ${formType === 'issue' ? 'active' : ''}`}
          onClick={() => setFormType('issue')}
        >
          Report an Issue
        </button>
        <button
          className={`feedback-button ${formType === 'feedback' ? 'active' : ''}`}
          onClick={() => setFormType('feedback')}
        >
          Provide Feedback
        </button>
      </div>
      <p className="explanatory-text">
        At Wallet Collector, we are always trying to optimize the experience for our users and are happy to hear any and all feedback to create a better tool for The Community. We are also committed to resolving issues promptly and providing reliable support to ensure a seamless experience.

      </p>
      <div className="twitter-button">
        <a
          href="https://x.com/0xwalletcollect"
          target="_blank"
          rel="noopener noreferrer"
          className="twitter-link"
        >
          Follow us on X
        </a>
      </div>
      {formType === 'submitted' && (
        <p className="thank-you-message">
          {formType === 'issue'
            ? 'Thank you for reporting the issue. We will look into it and get back to you as soon as we can.'
            : 'Thank you for your feedback! We value your input.'}
        </p>
      )}
      {formType !== 'submitted' && formType && (
        <div className="contact-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <label>
              Title/Subject:
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleInputChange}
                required
                placeholder={
                  formType === 'issue'
                    ? 'Brief description of the issue'
                    : 'Brief description of your feedback'
                }
              />
            </label>
            <label>
              Description:
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
                placeholder={
                  formType === 'issue'
                    ? 'Please provide details about the issue you encountered'
                    : 'Please provide details about your feedback'
                }
              />
            </label>
            <label>
              Email (optional):
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Your email address"
              />
            </label>
            <button type="submit">
              Submit {formType === 'issue' ? 'Issue' : 'Feedback'}
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ContactUs;








