/**
 * @file   Validation.js
 * @brief  Contains utility functions for validating Ethereum and Solana addresses.
 *         Key Features:Separate validation logic for Ethereum and Solana addresses. Generalized function to handle both blockchain  *         types.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */




import { isAddress } from 'ethers'; // For Ethereum validation
import bs58 from 'bs58'; // For Base58 decoding (install with `npm install bs58`)

/**
 * Validate Ethereum address
 * @param {string} address - The Ethereum address to validate
 * @returns {boolean} - Returns true if valid, otherwise false
 */
export const validateEthereumAddress = (address) => {
  try {
    return isAddress(address);
  } catch {
    return false;
  }
};

/**
 * Validate Solana address
 * @param {string} address - The Solana address to validate
 * @returns {boolean} - Returns true if valid, otherwise false
 */
export const validateSolanaAddress = (address) => {
  try {
    // Decode the address using Base58
    const decoded = bs58.decode(address);

    // Check if the length of the decoded address matches Solana's public key size (32 bytes)
    return decoded.length === 32;
  } catch (error) {
    // Invalid Base58 address
    return false;
  }
};

/**
 * General address validator for Ethereum and Solana
 * @param {string} address - The address to validate
 * @param {string} type - The blockchain type ('ethereum' or 'solana')
 * @returns {boolean} - Returns true if valid, otherwise false
 */
export const validateAddress = (address, type) => {
  if (type === 'ethereum') {
    return validateEthereumAddress(address);
  }
  if (type === 'solana') {
    return validateSolanaAddress(address);
  }
  return false;
};


