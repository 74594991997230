/**
 * @file   navbar.js
 * @brief  Implements a responsive navigation bar with Firebase authentication. Provides options for signing in, signing out, and  *         navigating the app.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */






// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';

// Import MUI components
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Navbar = ({ user }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

 const menuItems = user
    ? [
        { text: 'Create List', to: '/create' },
        { text: 'My Lists', to: '/profile' },
        { text: 'About', to: '/about' },
        { text: 'Contact Us', to: '/contactus' },
        { text: 'Sign Out', action: handleSignOut },
      ]
    : [
        { text: 'Sign In', action: handleSignIn },
        { text: 'About', to: '/about' },
        { text: 'Contact Us', to: '/contactus' },
        { text: 'Terms of Service', to: '/terms' },
        { text: 'Privacy Policy', to: '/policy' },
      ];


  return (
    <AppBar position="static">
      <Toolbar>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ flexGrow: 1, color: 'inherit', textDecoration: 'none' }}
        >
          WalletCollector
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleMenuOpen}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {menuItems.map((item, index) => (
                <MenuItem
                  key={index}
                  component={item.to ? Link : 'div'}
                  to={item.to}
                  onClick={() => {
                    handleMenuClose();
                    if (item.action) {
                      item.action();
                    }
                  }}
                >
                  {item.text}
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            {menuItems.map((item, index) =>
              item.action ? (
                <Button
                  key={index}
                  color="inherit"
                  onClick={item.action}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {item.text}
                </Button>
              ) : (
                <Button
                  key={index}
                  color="inherit"
                  component={Link}
                  to={item.to}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  {item.text}
                </Button>
              )
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;


