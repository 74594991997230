/**
 * @file   terms.js
 * @brief  terms and conditions for all walletcollector users
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */



import React from 'react';

const TermsOfServicePage = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '1rem' }}>Terms of Service</h1>
            <p style={{ textAlign: 'center', fontSize: '1rem', color: '#555' }}>Last Updated: December 06, 2024</p>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>1. Acceptance of Terms</h2>
                <p>By accessing or using the Wallet Collector app ("the App"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you must not use the App.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>2. Description of Services</h2>
                <p>The Wallet Collector app provides tools for collecting and managing Ethereum and Solana wallet addresses submitted for raffles, ICOs, or whitelist registrations. We do not operate or manage raffles, ICOs, or whitelist registrations, nor do we guarantee the legality, compliance, or fairness of such activities conducted by users of the App.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>3. User Responsibilities</h2>
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>a. Compliance with Laws</h3>
                <p>Users are solely responsible for ensuring that their use of the App complies with all applicable laws and regulations, including but not limited to those governing raffles, contests, ICOs, and cryptocurrency transactions in their respective jurisdictions.</p>
                
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>b. Accurate Information</h3>
                <p>Users are responsible for the accuracy and legality of any wallet addresses or other information submitted through the App.</p>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>c. Prohibited Activities</h3>
                <p>Users may not use the App to:
                    <ul>
                        <li>Engage in illegal, fraudulent, or malicious activities.</li>
                        <li>Submit false, inaccurate, or misleading information.</li>
                        <li>Interfere with or disrupt the operation of the App.</li>
                        <li>Violate the rights of others, including privacy or intellectual property rights.</li>
                    </ul>
                </p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>4. Account Management</h2>
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>a. User Accounts</h3>
                <p>Users may be required to create an account to access certain features of the App. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>b. Termination</h3>
                <p>We reserve the right to suspend or terminate accounts that violate these Terms or engage in prohibited activities, with or without prior notice.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>5. Data and Privacy</h2>
                <p>Your use of the App is governed by our Privacy Policy, which explains how we collect, use, and safeguard your information. By using the App, you consent to the practices described in the Privacy Policy.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>6. Disclaimers</h2>
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>a. No Guarantee of Legality or Compliance</h3>
                <p>The App is a tool for managing wallet addresses. We do not guarantee the legality, compliance, or fairness of any raffles, ICOs, or whitelist registrations conducted by users.</p>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>b. Use at Your Own Risk</h3>
                <p>The App is provided "as is" and "as available." We disclaim all warranties, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. You use the App at your own risk.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>7. Limitation of Liability</h2>
                <p>To the fullest extent permitted by law, Bissonette Data Solutions Corporation shall not be liable for any indirect, incidental, consequential, or punitive damages, or for any loss of data, revenue, or profits arising from your use of the App. Our total liability for any claims arising out of or related to the App shall not exceed the amount you paid (if any) to use the App.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>8. Indemnification</h2>
                <p>You agree to indemnify and hold harmless Bissonette Data Solutions Corporation, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or related to your use of the App, violation of these Terms, or violation of any rights of a third party.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>9. Intellectual Property</h2>
                <p>The App and its content, features, and functionality are owned by Bissonette Data Solutions Corporation and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, modify, distribute, or create derivative works based on the App without our express written consent.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>10. Changes to These Terms</h2>
                <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes are effective immediately upon posting.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>11. Governing Law and International Compliance</h2>
                <p>These Terms are governed by and construed in accordance with the laws of Canada. For users in the United States, European Union, and Australia, we comply with applicable laws and regulations, including GDPR, PIPEDA, and Australia’s Privacy Act 1988. Any disputes arising out of or related to these Terms or your use of the App shall be resolved in the courts of Canada or in the relevant jurisdiction where required by applicable international laws.</p>
            </section>

            <section>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>12. Contact Us</h2>
                <p>If you have any questions about these Terms of Service, please contact us at walletcollectormain@gmail.com</p>
            </section>
        </div>
    );
};

export default TermsOfServicePage;
