/**
 * @file   profilePage.js
 * @brief  Displays the user's profile information and their created raffles. Allows users to view or delete their raffles.
 *         Key Features: Fetches raffles owned by the authenticated user. Enables deletion of raffles.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */



// src/pages/ProfilePage.js
import React, { useEffect, useState } from 'react';
import { firestore, auth } from '../firebase';
import {
  collection,
  query,
  where,
  getDocs,
  getDoc, // Added import for getDoc
  setDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { deleteUser } from 'firebase/auth';
import { Link } from 'react-router-dom';
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const ProfilePage = ({ user }) => {
  const [raffles, setRaffles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [monthlyRaffleCount, setMonthlyRaffleCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [nextResetDate, setNextResetDate] = useState(null);

  useEffect(() => {
    const fetchRaffles = async () => {
      try {
        const rafflesRef = collection(firestore, 'raffles');
        const q = query(rafflesRef, where('ownerId', '==', user.uid));
        const querySnapshot = await getDocs(q);

        const currentMonthStart = new Date();
        currentMonthStart.setDate(1);
        currentMonthStart.setHours(0, 0, 0, 0);

        // Calculate monthly raffle count including deleted raffles
        const monthlyRaffles = querySnapshot.docs.filter((docSnap) =>
          docSnap.data().createdAt.toDate() >= currentMonthStart
        );
        setMonthlyRaffleCount(monthlyRaffles.length);

        // Set next reset date to the first day of the next month
        const nextMonth = new Date(currentMonthStart);
        nextMonth.setMonth(currentMonthStart.getMonth() + 1);
        setNextResetDate(nextMonth);

        // Map through raffles, exclude deleted ones, and sort by createdAt
        const fetchedRaffles = await Promise.all(
          querySnapshot.docs
            .filter((docSnap) => !docSnap.data().deleted) // Exclude deleted raffles
            .map(async (docSnap) => {
              const raffleData = { id: docSnap.id, ...docSnap.data() };

              // Fetch the entrant count for the raffle
              const entrantsQuery = query(
                collection(firestore, 'raffleEntries'),
                where('raffleId', '==', docSnap.id)
              );
              const entrantsSnapshot = await getDocs(entrantsQuery);
              raffleData.entrantCount = entrantsSnapshot.size || 0;

              return raffleData;
            })
        );

        // Sort by createdAt in descending order
        setRaffles(
          fetchedRaffles.sort((a, b) =>
            b.createdAt && a.createdAt
              ? b.createdAt.toMillis() - a.createdAt.toMillis()
              : 0
          )
        );
      } catch (error) {
        console.error('Error fetching raffles:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSubscriptionStatus = async () => {
      try {
        const userRef = doc(firestore, 'users', user.uid);
        const userSnapshot = await getDoc(userRef); // Changed from getDocs to getDoc
        if (userSnapshot.exists()) {
          setIsPremium(userSnapshot.data().isPremium || false);
        }
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    if (user) {
      fetchRaffles();
      fetchSubscriptionStatus();
    }
  }, [user]);

  const handleDeleteRaffle = async (raffleId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this raffle? This action cannot be undone.'
    );
    if (!confirmDelete) return;

    try {
      // Soft delete: Set 'deleted' field to true
      const raffleRef = doc(firestore, 'raffles', raffleId);
      await setDoc(raffleRef, { deleted: true }, { merge: true });

      // Update local state to remove the raffle from the UI
      setRaffles(raffles.filter((raffle) => raffle.id !== raffleId));

      alert('Raffle deleted successfully.');
    } catch (error) {
      console.error('Error deleting raffle:', error);
      alert('Failed to delete the raffle. Please try again.');
    }
  };

  const handleDeleteAccount = async () => {
    try {
      const rafflesRef = collection(firestore, 'raffles');
      const q = query(rafflesRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const batchDeletePromises = querySnapshot.docs.map((docSnap) =>
        deleteDoc(doc(firestore, 'raffles', docSnap.id))
      );
      await Promise.all(batchDeletePromises);

      await deleteUser(auth.currentUser);
      alert('Account deleted successfully.');
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Failed to delete the account. Please try again.');
    }
  };

  const openDeleteDialogHandler = () => setOpenDeleteDialog(true);
  const closeDeleteDialogHandler = () => setOpenDeleteDialog(false);

  const handleUpgradeToPremium = () => {
    
  };

  if (!user) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="body1">
          Please sign in to view your profile.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '2rem' }}>
      <Typography variant="h5" gutterBottom>
        Your Profile
      </Typography>

      <Grid container spacing={2} alignItems="center" sx={{ marginBottom: '2rem' }}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">Name: {user.displayName}</Typography>
          <Typography variant="body1">Email: {user.email}</Typography>
          <Typography variant="body1">
            Account Status: {isPremium ? 'Premium' : 'Free'}
          </Typography>
          <Box display="flex" alignItems="center">
            <Tooltip
              title={
                nextResetDate
                  ? `Your raffle count will reset on ${nextResetDate.toLocaleDateString()}`
                  : ''
              }
            >
              <Typography variant="body1" sx={{ cursor: 'pointer' }}>
                Monthly Raffles Created: {monthlyRaffleCount} {isPremium ? '/ Unlimited' : '/ 5'}
              </Typography>
            </Tooltip>
            <Tooltip
              title={
                nextResetDate
                  ? `Your raffle count will reset on ${nextResetDate.toLocaleDateString()}`
                  : 'More info about raffle limits'
              }
            >
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
          <Button
  variant="outlined"
  color="secondary"
  onClick={handleUpgradeToPremium}
  sx={{
    mt: 2,
    px: 4,
    py: 1,
    position: 'relative',
    overflow: 'hidden',
    // Hide the original text on hover
    '& .original-text': {
      transition: 'opacity 0.3s',
      opacity: 1,
    },
    '&:hover .original-text': {
      opacity: 0,
    },
    // Show the hover text on hover
    '& .hover-text': {
      transition: 'opacity 0.3s',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&:hover .hover-text': {
      opacity: 1,
    },
  }}
>
  {/* Original Button Text */}
  <span className="original-text">Upgrade to Premium</span>
  
  {/* Hover Text */}
  <span className="hover-text">Coming soon!</span>
</Button>
        </Grid>
      </Grid>

      <Typography variant="h5" gutterBottom>
        Your Lists
      </Typography>
      {loading ? (
        <Typography variant="body2">Loading Lists...</Typography>
      ) : raffles.length === 0 ? (
        <Typography variant="body2">No raffles to display.</Typography>
      ) : (
        <List>
          {raffles.map((raffle) => {
            const isOpen = new Date(raffle.entryDeadline) > new Date();
            return (
              <ListItem key={raffle.id} divider sx={{ flexWrap: 'wrap' }}>
                <ListItemText
                  primary={raffle.name}
                  secondary={
                    <>
                      Type: {raffle.raffleType} -{' '}
                      <Typography
                        component="span"
                        sx={{
                          color: isOpen ? 'green' : 'red',
                          fontWeight: 'bold',
                        }}
                      >
                        {isOpen ? 'Open' : 'Closed'}
                      </Typography>{' '}
                      - Entrants: {raffle.entrantCount}
                    </>
                  }
                  sx={{ minWidth: '200px' }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: '0.5rem',
                    marginTop: { xs: '1rem', sm: '0' },
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/raffle/${raffle.id}`}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleDeleteRaffle(raffle.id)}
                    sx={{ width: { xs: '100%', sm: 'auto' } }}
                  >
                    Delete
                  </Button>
                </Box>
              </ListItem>
            );
          })}
        </List>
      )}

      <Button
        variant="outlined"
        color="error"
        onClick={openDeleteDialogHandler}
        sx={{ marginTop: '2rem' }}
      >
        Delete My Account
      </Button>

      <Dialog open={openDeleteDialog} onClose={closeDeleteDialogHandler}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete your account? This action cannot
            be undone, and all your lists will be permanently deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialogHandler} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeDeleteDialogHandler();
              handleDeleteAccount();
            }}
            color="error"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProfilePage;


