/**
 * @file   App.js
 * @brief  Serves as the root component, managing routing and user authentication state. Renders the navigation bar and routes to  *         specific pages.
 *         Key Features:Routes defined for homepage, profile page, raffle creation, and raffle details. Authentication state  *         management with Firebase.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */


// src/App.js

import React, { useEffect, useState } from 'react';
import { auth } from './firebase';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer'; // Import Footer
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import RafflePage from './pages/RafflePage';
import RaffleEntryPage from './pages/RaffleEntryPage';
import RaffleCreationForm from './components/RaffleCreationForm';
import ContactUs from './pages/ContactUs';
import PrivacyPolicyPage from './pages/policy';
import TermsOfServicePage from './pages/terms';
import AboutPage from './pages/about';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Set up an authentication state observer
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Navbar user={user} />
      <Routes>
        <Route path="/" element={<HomePage user={user} />} />
        <Route path="/profile" element={<ProfilePage user={user} />} />
        <Route path="/create" element={<RaffleCreationForm user={user} />} />
        <Route path="/raffle/:id" element={<RafflePage user={user} />} />
        <Route path="/raffle/entry/:id" element={<RaffleEntryPage user={user} />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms" element={<TermsOfServicePage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;




