/**
 * @file   RaffleEntriesList.js
 * @brief  Displays a list of entries for a specific raffle. Intended for viewing by the raffle creator.
 *         Key Features: Fetches and displays raffle entries from Firestore. Handles loading and error states.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */


// src/components/RaffleEntriesList.js
import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase';
import { collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';

// Import Material-UI components
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

const RaffleEntriesList = ({ raffleId, isRaffleOwner }) => {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true); // Indicates if data is loading
  const [error, setError] = useState(null);     // Holds any error messages
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [copied, setCopied] = useState(false);
  
  // State for file download
  const [fileType, setFileType] = useState('.txt');

  useEffect(() => {
    if (!raffleId) {
      setEntries([]);
      setLoading(false);
      return;
    }

    const entriesRef = collection(firestore, 'raffleEntries');
    const q = query(
      entriesRef,
      where('raffleId', '==', raffleId),
      orderBy('createdAt', 'desc')
    );

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const entriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt ? doc.data().createdAt.toDate().toLocaleString() : 'N/A',
        }));
        setEntries(entriesData);
        setLoading(false);
      },
      (error) => {
        console.error('Error fetching entries:', error);
        setError('Failed to load raffle entries.');
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [raffleId]);

  const handleSearch = () => {
    const result = entries.find((entry) => entry.address === searchQuery.trim());
    setSearchResult(result);
  };

  const handleCopyAddresses = async () => {
    try {
      const addresses = entries.map((entry) => entry.address).join('\n');
      await navigator.clipboard.writeText(addresses);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
    } catch (err) {
      console.error('Failed to copy addresses:', err);
    }
  };

  const handleDownload = () => {
    const addresses = entries.map((entry) => entry.address);

    let fileContent = '';
    if (fileType === '.json') {
      // JSON file: just addresses
      fileContent = JSON.stringify(addresses, null, 2);
    } else if (fileType === '.csv') {
      // CSV file: just addresses
      const header = 'address';
      fileContent = [header, ...addresses].join('\n');
    } else {
      // TXT or other text formats: newline-separated addresses
      fileContent = addresses.join('\n');
    }

    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `raffle_entries${fileType}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '1rem' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
          Loading raffle entries...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '1rem' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (entries.length === 0) {
    return (
      <Box sx={{ padding: '1rem' }}>
        <Typography variant="body1">No entries yet.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '1rem' }}>
      <Typography variant="h5" gutterBottom>
        Raffle Entries
      </Typography>

      {/* Search bar for raffle owner */}
      {isRaffleOwner && (
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Search by Wallet Address"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            onClick={handleSearch}
            fullWidth
          >
            Search
          </Button>
          {searchResult && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                <strong>Address:</strong> {searchResult.address}
              </Typography>
              <Typography variant="body1">
                <strong>Twitter Handle:</strong> {searchResult.twitterHandle || 'N/A'}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {/* Addresses list */}
      <Box sx={{ mb: 3 }}>
        <TextField
          label="Entrant Addresses"
          multiline
          fullWidth
          rows={entries.length > 10 ? 10 : entries.length || 1}
          value={entries.map((entry) => entry.address).join('\n')}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />

        {/* Container for Copy and Download options */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'start',
            gap: 2,
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleCopyAddresses}
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            {copied ? 'Copied to Clipboard' : 'Copy Addresses'}
          </Button>

          {isRaffleOwner && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                alignItems: 'center',
                width: { xs: '100%', md: 'auto' }
              }}
            >
              <FormControl variant="outlined" sx={{ minWidth: 120, width: { xs: '100%', md: 'auto' } }}>
                <InputLabel id="file-type-label">File Type</InputLabel>
                <Select
                  labelId="file-type-label"
                  value={fileType}
                  onChange={(e) => setFileType(e.target.value)}
                  label="File Type"
                >
                  <MenuItem value=".txt">.txt</MenuItem>
                  <MenuItem value=".json">.json</MenuItem>
                  <MenuItem value=".csv">.csv</MenuItem>
                </Select>
              </FormControl>

              <Button
                variant="contained"
                color="secondary"
                onClick={handleDownload}
                sx={{ width: { xs: '100%', md: 'auto' } }}
              >
                Download
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RaffleEntriesList;


