/**
 * @file   HomePage.js
 * @brief  Serves as the landing page for the app, providing a welcoming message and actions based on the user's authentication  *         status. Key Features: Displays buttons for creating or entering raffles.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */



import { Link } from 'react-router-dom';
import { Button, Typography, Box, Grid } from '@mui/material';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';


const HomePage = ({ user }) => {
  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const handleUpgradeToPremium = () => {
    // Placeholder for future Stripe integration
    
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Ensures full viewport height
        padding: { xs: '1rem', md: '2rem' },
      }}
    >
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          flexGrow: 1,
          marginTop: { xs: '-150px', sm: '-150px', md: '-150px', lg: '-150px' }, // Move up by ~1 inch
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontSize: { xs: '2rem', md: '3rem' },
              marginBottom: { xs: '1rem', md: '1.5rem' },
            }}
          >
            Welcome to WalletCollector
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {user ? (
            <Box textAlign="center">
              <Typography variant="body1" gutterBottom>
                Effortlessly collect your followers' Ethereum or Solana wallets into manageable lists for your social media giveaways!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/create"
                sx={{ mt: 2, px: 4, py: 1 }}
              >
                Create Wallet Collector
              </Button>
              <Button
  variant="outlined"
  color="secondary"
  onClick={handleUpgradeToPremium}
  sx={{
    mt: 2,
    px: 4,
    py: 1,
    position: 'relative',
    overflow: 'hidden',
    // Hide the original text on hover
    '& .original-text': {
      transition: 'opacity 0.3s',
      opacity: 1,
    },
    '&:hover .original-text': {
      opacity: 0,
    },
    // Show the hover text on hover
    '& .hover-text': {
      transition: 'opacity 0.3s',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&:hover .hover-text': {
      opacity: 1,
    },
  }}
>
  {/* Original Button Text */}
  <span className="original-text">Upgrade to Premium</span>
  
  {/* Hover Text */}
  <span className="hover-text">Coming soon!</span>
</Button>

            </Box>
          ) : (
            <Box textAlign="center">
              <Typography variant="body1" gutterBottom>
                Enter your crypto address into your desired list or sign in to create your own.
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'center',
                  gap: '1rem',
                  mt: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSignIn}
                  sx={{ px: 4, py: 1, width: { xs: '100%', sm: 'auto' } }}
                >
                  Sign In with Google
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSignIn}
                  sx={{ px: 4, py: 1, width: { xs: '100%', sm: 'auto' } }}
                >
                  Create Account with Google
                </Button>
              </Box>
              <Typography
  variant="caption"
  align="center"
  sx={{ mt: 2, fontSize: '0.65rem' }}
>
  By using Wallet Collector, you confirm that you have read and agree to the{' '}
  <Link to="/terms" target="_blank" rel="noopener noreferrer">
    Terms
  </Link>{' '}
  and{' '}
  <Link to="/policy" target="_blank" rel="noopener noreferrer">
    Privacy Policy
  </Link>.
</Typography>

            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;


