/**
 * @file   RaffleEntryPage.js
 * @brief  Shows detailed information about a specific raffle, including its description, entry deadline, and entries. Allows users  *         to submit entries or copy entry data.Key Features: Displays raffle details and total number of entries. Conditional         *         rendering for raffle owner to view all entries. Entry submission form with validation.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */



// src/pages/RafflePage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import RaffleEntryForm from '../components/RaffleEntryForm';
import RaffleEntriesList from '../components/RaffleEntriesList';
import { Helmet } from 'react-helmet'; // Import Helmet for Open Graph meta tags
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getCountFromServer,
} from 'firebase/firestore';
import {
  Typography,
  Box,
  Grid,
  CircularProgress,
  Alert,
  TextField,
  Button,
} from '@mui/material';

const RafflePage = ({ user }) => {
  const { id } = useParams(); // Extract 'id' from URL parameters
  const [raffle, setRaffle] = useState(null);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling
  const [totalEntries, setTotalEntries] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const fetchRaffleData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch raffle details
        const raffleRef = doc(firestore, 'raffles', id);
        const raffleSnap = await getDoc(raffleRef);

        if (raffleSnap.exists()) {
          const raffleData = { id: raffleSnap.id, ...raffleSnap.data() };
          setRaffle(raffleData);

          // Fetch total number of entries using getCountFromServer
          const entriesRef = collection(firestore, 'raffleEntries');
          const q = query(entriesRef, where('raffleId', '==', id));
          const countSnap = await getCountFromServer(q);
          setTotalEntries(countSnap.data().count);
        } else {
          setError('No such raffle found!');
        }
      } catch (err) {
        console.error('Error fetching raffle:', err);
        setError('Failed to load raffle.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchRaffleData();
    }
  }, [id, user]);

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
          Loading List...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' }, minHeight: '100vh' }}>
      {/* Adding Open Graph meta tags for social sharing */}
      <Helmet>
        <title>{raffle.name} - Crypto Wallet Collector</title>
        <meta property="og:title" content={raffle.name} />
        <meta property="og:description" content={raffle.description || 'Join this exciting raffle and stand a chance to win!'} />
        <meta property="og:image" content={raffle.imageUrl || "https://example.com/default-image.jpg"} />
        <meta property="og:url" content={`${window.location.origin}/raffle/${raffle.id}`} />
      </Helmet>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: '2rem', md: '2.5rem' }, textAlign: { xs: 'center', md: 'left' } }}
          >
            {raffle.name}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: { xs: 'center', md: 'left' } }}
          >
            {raffle.description}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body2" gutterBottom>
            <strong>Type:</strong> {raffle.raffleType}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Entry Deadline:</strong>{' '}
            {new Date(raffle.entryDeadline).toLocaleString()}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Max Entries per Address:</strong>{' '}
            {raffle.maxEntriesPerAddress}
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Total Number of Entrants:</strong>{' '}
            {totalEntries !== null ? totalEntries : 'Loading...'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {user && user.uid === raffle.ownerId ? (
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ textAlign: { xs: 'center', sm: 'left' } }}
              >
                Shareable Link:
              </Typography>
              <TextField
                value={`${window.location.origin}/raffle/${raffle.id}`}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      `${window.location.origin}/raffle/${raffle.id}`
                    );
                    setCopied(true);
                    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
                  } catch (err) {
                    console.error('Failed to copy link:', err);
                  }
                }}
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >
                {copied ? 'Link Copied!' : 'Copy Link'}
              </Button>
            </Box>
          ) : (
            <RaffleEntryForm
              raffleId={raffle.id}
              raffleType={raffle.raffleType}
              user={user}
            />
          )}
        </Grid>

        {user && user.uid === raffle.ownerId && (
          <Grid item xs={12}>
            <RaffleEntriesList raffleId={raffle.id} isRaffleOwner={true} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RafflePage;


