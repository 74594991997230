/**
 * @file   RaffleEntryPage.js
 * @brief  Dedicated page for entering a raffle. Allows users to submit their addresses and displays existing entries for raffle       *         owners. Key Features: Validates Ethereum and Solana addresses. Handles submission to Firestore. Fetches and displays        *         existing entries for raffle owners.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */


// src/pages/RaffleEntryPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getCountFromServer,
  getDocs,
} from 'firebase/firestore';
import { validateAddress } from '../utils/Validation';
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

const RaffleEntryPage = ({ user }) => {
  const { raffleId } = useParams();
  const [raffle, setRaffle] = useState(null);
  const [address, setAddress] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [ageError, setAgeError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalEntries, setTotalEntries] = useState(null);
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    const fetchRaffleData = async () => {
      try {
        const raffleRef = doc(firestore, 'raffles', raffleId);
        const raffleSnap = await getDoc(raffleRef);
        if (raffleSnap.exists()) {
          const raffleData = raffleSnap.data();
          setRaffle(raffleData);

          // Fetch total number of entries using getCountFromServer
          const entriesRef = collection(firestore, 'raffleEntries');
          const q = query(entriesRef, where('raffleId', '==', raffleId));
          const countSnap = await getCountFromServer(q);
          setTotalEntries(countSnap.data().count);

          // If the user is the raffle owner, fetch all entries
          if (user && user.uid === raffleData.ownerId) {
            const entriesSnap = await getDocs(q);
            const entriesList = entriesSnap.docs.map((doc) => doc.data());
            setEntries(entriesList);
          }
        } else {
          setError('List not found.');
        }
      } catch (err) {
        console.error('Error fetching list:', err);
        setError('Failed to load list.');
      } finally {
        setLoading(false);
      }
    };

    fetchRaffleData();
  }, [raffleId, user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess(false);
    setAgeError('');

    if (raffle?.enableAgeGate && !ageConfirmed) {
      setAgeError(`You must confirm that you are at least ${raffle.minimumAge} years old.`);
      return;
    }

    if (!validateAddress(address.trim(), raffle?.raffleType)) {
      setError(`Invalid ${raffle?.raffleType || 'unknown'} address.`);
      return;
    }

    if (!twitterHandle.trim().startsWith('@')) {
      setError('Please enter a valid Twitter handle starting with @.');
      return;
    }

    try {
      const entriesRef = collection(firestore, 'raffleEntries');

      // Check for duplicate entries based on address
      const q = query(
        entriesRef,
        where('raffleId', '==', raffleId),
        where('address', '==', address.trim())
      );
      const existingEntriesSnap = await getDocs(q);
      const entryCount = existingEntriesSnap.size;

      if (entryCount >= raffle.maxEntriesPerAddress) {
        setError('You have reached the maximum number of entries allowed for this address.');
        return;
      }

      await addDoc(entriesRef, {
        raffleId,
        address: address.trim(),
        twitterHandle: twitterHandle.trim(),
        userId: user ? user.uid : null,
        createdAt: serverTimestamp(),
      });
      setSuccess(true);
      setAddress('');
      setTwitterHandle('');
      setAgeConfirmed(false);
      // Update total entries count
      setTotalEntries((prevCount) => (prevCount !== null ? prevCount + 1 : null));
    } catch (err) {
      console.error('Error submitting entry:', err);
      setError('Failed to submit your entry.');
    }
  };

  if (loading)
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: '1rem' }}>
          Loading List...
        </Typography>
      </Box>
    );

  if (error)
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );

  if (success)
    return (
      <Box sx={{ padding: '2rem' }}>
        <Alert severity="success">Thank you for entering the list!</Alert>
      </Box>
    );

  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}
          >
            {raffle?.name || 'List'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1">
            {raffle?.description || 'No description provided.'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom sx={{ marginTop: '1rem' }}>
              Enter your {raffle?.raffleType === 'ethereum' ? 'Ethereum' : 'Solana'} address:
            </Typography>
            <TextField
              id="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={`Enter your ${raffle?.raffleType} address`}
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />
            <TextField
              id="twitterHandle"
              type="text"
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
              placeholder="Enter your Twitter handle (e.g., @username)"
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />

            {/* Age Confirmation */}
            {raffle?.enableAgeGate && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ageConfirmed}
                    onChange={(e) => setAgeConfirmed(e.target.checked)}
                    name="ageConfirmed"
                  />
                }
                label={`I confirm that I am at least ${raffle.minimumAge} years old.`}
              />
            )}
            {ageError && (
              <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
                {ageError}
              </Typography>
            )}

            {error && (
              <Typography variant="body2" color="error" sx={{ marginTop: '0.5rem' }}>
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginTop: '1rem' }}
              fullWidth
            >
              Submit
            </Button>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" sx={{ marginTop: '1rem' }}>
            Total number of entrants: {totalEntries !== null ? totalEntries : 'Loading...'}
          </Typography>
        </Grid>

        {/* Display entries only if the user is the raffle owner */}
        {user && user.uid === raffle?.ownerId && (
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ marginTop: '2rem' }}>
              Entries:
            </Typography>
            <List>
              {entries.map((entry, index) => (
                <ListItem key={index} divider>
                  <ListItemText primary={`${entry.address} - ${entry.twitterHandle}`} />
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RaffleEntryPage;



