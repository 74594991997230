/**
 * @file   policy.js
 * @brief  privacy policy legal doc page
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */


import React from 'react';

const PrivacyPolicyPage = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '1rem' }}>Privacy Policy</h1>
            <p style={{ textAlign: 'center', fontSize: '1rem', color: '#555' }}>Last Updated: December 06, 2024</p>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>1. Introduction</h2>
                <p>Bissonette Data Solutions Corporation ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use the Wallet Collector app ("the App"). Please read this policy carefully. By using the App, you agree to the terms of this Privacy Policy.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>2. Information We Collect</h2>
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>a. Personal Information</h3>
                <ul>
                    <li>Entrants:
                        <ul>
                            <li>Ethereum or Solana wallet addresses submitted for raffles, ICOs, or whitelist registrations.</li>
                            <li>Email addresses (if provided voluntarily for contact purposes).</li>
                        </ul>
                    </li>
                    <li>Creators:
                        <ul>
                            <li>Contact information (e.g., email address) for account management and support.</li>
                        </ul>
                    </li>
                </ul>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>b. Automatically Collected Information</h3>
                <ul>
                    <li>Usage Data:
                        <ul>
                            <li>IP addresses, browser type, device information, and usage patterns when accessing the App.</li>
                        </ul>
                    </li>
                    <li>Blockchain Data:
                        <ul>
                            <li>Verification data from Ethereum and Solana blockchains to confirm the authenticity of submitted addresses.</li>
                        </ul>
                    </li>
                </ul>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>3. How We Use Your Information</h2>
                <ul>
                    <li><b>Service Management:</b> Compile and manage lists of Ethereum and Solana addresses for raffles, ICOs, and whitelist registrations.</li>
                    <li><b>Communication:</b> Respond to inquiries and provide support to users.</li>
                    <li><b>Security:</b> Verify the authenticity of wallet addresses using blockchain technology. Protect against unauthorized access and data breaches using Firebase Firestore security measures.</li>
                    <li><b>Improvement of Services:</b> Analyze usage data to enhance and optimize the App’s functionality and user experience.</li>
                </ul>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>4. Data Storage and Security</h2>
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>a. Storage</h3>
                <p>User data is stored securely using Firebase Firestore, adhering to industry-standard security practices.</p>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>b. Security Measures</h3>
                <p>Encryption of data in transit and at rest. Regular security assessments to protect against unauthorized access, disclosure, alteration, or destruction of data.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>5. Data Sharing and Disclosure</h2>
                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>a. Third-Party Services</h3>
                <p>We use Firebase Firestore for data storage, which may involve data processing by Google. These third parties are bound by strict confidentiality and security obligations.</p>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>b. Legal Requirements</h3>
                <p>We may disclose your information if required by Canadian law or in response to valid legal requests.</p>

                <h3 style={{ fontSize: '1.25rem', marginBottom: '0.5rem' }}>c. Business Transfers</h3>
                <p>In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction, subject to confidentiality agreements.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>6. User Rights and Choices</h2>
                <ul>
                    <li><b>Access and Correction:</b> You may request access to or correction of your personal information by contacting us at walletcollectormain@gmail.com</li>
                    <li><b>Deletion:</b> You may request the deletion of your personal information, understanding that it may affect your ability to use certain features of the App.</li>
                    <li><b>Opt-Out:</b> You may opt out of receiving promotional communications by following the unsubscribe instructions provided in such communications.</li>
                </ul>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>7. Children’s Privacy</h2>
                <p>The App is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from minors. If we become aware that we have inadvertently collected such information, we will take steps to delete it promptly.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>8. International Data Transfers</h2>
                <p>Your information may be processed and stored in servers located outside your country of residence, including Canada. By using the App, you consent to the transfer of your information to countries with different data protection laws.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>9. Disclaimer</h2>
                <p>The Wallet Collector App is a tool for collecting and managing Ethereum and Solana wallet addresses. We do not guarantee or take responsibility for the legality, compliance, or fairness of raffles, ICOs, or whitelist registrations conducted using the App. Users are solely responsible for ensuring compliance with all applicable laws and regulations in their respective jurisdictions.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>10. Limitation of Liability</h2>
                <p>Bissonette Data Solutions Corporation shall not be held liable for any loss, damages, or disputes arising from the use of the Wallet Collector App. Users are responsible for verifying the authenticity and legality of wallet addresses and the raffles or registrations they participate in.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>11. Blockchain Data Disclaimer</h2>
                <p>The Wallet Collector App uses publicly available blockchain data to verify wallet addresses. Due to the immutable nature of blockchain, we cannot modify or delete data recorded on the blockchain. Users are responsible for verifying the accuracy of any submitted wallet addresses.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>12. User Responsibility for Content</h2>
                <p>Users are solely responsible for the accuracy and legality of the wallet addresses they submit. The Wallet Collector App does not independently verify ownership or additional details beyond blockchain validation.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>13. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes are effective immediately upon posting.</p>
            </section>

            <section>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>14. Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at walletcollectormain@gmail.com</p>
            </section>
        </div>
    );
};

export default PrivacyPolicyPage;
