/**
 * @file   RaffleCreationForm.js
 * @brief  Form validation for input fields.Saves raffle data to Firestore.Generates and displays a shareable link for the raffle.
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */



// src/components/RaffleCreationForm.js
import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

import {
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Alert,
  Tooltip,
  IconButton
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const RaffleCreationForm = ({ user }) => {
  const [raffleDetails, setRaffleDetails] = useState({
    name: '',
    description: '',
    raffleType: '',
    entryDate: '',
    entryTime: '',
    maxEntriesPerAddress: '',
    enableAgeGate: false,
    minimumAge: '',
    restrictedCountries: [],
  });
  const [errors, setErrors] = useState({});
  const [monthlyRaffleCount, setMonthlyRaffleCount] = useState(0);
  const [monthResetDate, setMonthResetDate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }

    const fetchMonthlyRaffleCount = async () => {
      try {
        const currentMonthStart = new Date();
        currentMonthStart.setDate(1);
        currentMonthStart.setHours(0, 0, 0, 0);

        const q = query(
          collection(firestore, 'raffles'),
          where('ownerId', '==', user.uid),
          where('createdAt', '>=', currentMonthStart)
        );

        const querySnapshot = await getDocs(q);
        setMonthlyRaffleCount(querySnapshot.size);

        if (querySnapshot.size > 0) {
          const createdAtTimestamp = querySnapshot.docs[0].data().createdAt;
          const resetDate = new Date(createdAtTimestamp.toDate());
          resetDate.setMonth(resetDate.getMonth() + 1);
          resetDate.setDate(1);
          resetDate.setHours(0, 0, 0, 0);
          setMonthResetDate(resetDate);
        } else {
          setMonthResetDate(currentMonthStart);
        }
      } catch (error) {
        console.error('Error fetching monthly raffle count:', error);
      }
    };

    fetchMonthlyRaffleCount();
  }, [user, navigate]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setRaffleDetails((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const validateForm = () => {
    const {
      name,
      raffleType,
      entryDate,
      entryTime,
      maxEntriesPerAddress,
      enableAgeGate,
      minimumAge,
    } = raffleDetails;
    const newErrors = {};

    if (!name.trim()) newErrors.name = 'List name is required.';
    if (!raffleType) newErrors.raffleType = 'Please select a list type.';
    if (!entryDate) newErrors.entryDate = 'Entry deadline date is required.';
    if (!entryTime) newErrors.entryTime = 'Entry deadline time is required.';
    if (
      !maxEntriesPerAddress ||
      isNaN(maxEntriesPerAddress) ||
      maxEntriesPerAddress <= 0
    ) {
      newErrors.maxEntriesPerAddress = 'Please enter a valid number greater than 0.';
    }
    if (enableAgeGate) {
      if (!minimumAge || isNaN(minimumAge) || minimumAge < 0) {
        newErrors.minimumAge = 'Please enter a valid minimum age.';
      }
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const createRaffle = async () => {
    if (monthlyRaffleCount >= 5) {
      alert('You have reached the monthly limit of 5 raffles. Upgrade to premium for unlimited raffles.');
      return;
    }

    if (!validateForm()) {
      return;
    }

    const {
      name,
      description,
      raffleType,
      entryDate,
      entryTime,
      maxEntriesPerAddress,
      enableAgeGate,
      minimumAge,
      restrictedCountries,
    } = raffleDetails;

    // Combine date and time into a single ISO 8601 string
    const entryDeadline = new Date(`${entryDate}T${entryTime}`).toISOString();

    const raffleData = {
      name,
      description,
      raffleType,
      entryDeadline,
      maxEntriesPerAddress: parseInt(maxEntriesPerAddress, 10),
      ownerId: user.uid,
      createdAt: new Date(),
      enableAgeGate,
      minimumAge: enableAgeGate ? parseInt(minimumAge, 10) : null,
      restrictedCountries,
    };

    try {
      const docRef = await addDoc(collection(firestore, 'raffles'), raffleData);
      navigate(`/raffle/${docRef.id}`);
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  if (!user) {
    return (
      <Box sx={{ padding: '2rem' }}>
        <Typography variant="body1">Please sign in to create a list.</Typography>
      </Box>
    );
  }
  const handleUpgradeToPremium = () => {
    
  };

  if (monthlyRaffleCount >= 5) {
    return (
      <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
        <Alert severity="info" sx={{ marginBottom: '1rem' }}>
          You have reached the monthly limit of 5 raffles. Upgrade to premium for unlimited raffles.
          <Tooltip title={`Your monthly limit will reset on ${monthResetDate ? new Date(monthResetDate).toLocaleDateString() : 'the start of the next month'}.`} placement="top">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        <Button
  variant="outlined"
  color="secondary"
  onClick={handleUpgradeToPremium}
  sx={{
    mt: 2,
    px: 4,
    py: 1,
    position: 'relative',
    overflow: 'hidden',
    // Hide the original text on hover
    '& .original-text': {
      transition: 'opacity 0.3s',
      opacity: 1,
    },
    '&:hover .original-text': {
      opacity: 0,
    },
    // Show the hover text on hover
    '& .hover-text': {
      transition: 'opacity 0.3s',
      opacity: 0,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&:hover .hover-text': {
      opacity: 1,
    },
  }}
>
  {/* Original Button Text */}
  <span className="original-text">Upgrade to Premium</span>
  
  {/* Hover Text */}
  <span className="hover-text">Coming soon!</span>
</Button>

        </Alert>
        <Typography variant="h5" gutterBottom>
          Create an Ethereum or Solana address collector to share with your followers
        </Typography>
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="List Name"
                name="name"
                value={raffleDetails.name}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={raffleDetails.description}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal" required disabled>
                <InputLabel id="raffle-type-label">List Type</InputLabel>
                <Select
                  labelId="raffle-type-label"
                  name="raffleType"
                  value={raffleDetails.raffleType}
                  onChange={handleInputChange}
                  label="List Type"
                >
                  <MenuItem value="">
                    <em>--Select--</em>
                  </MenuItem>
                  <MenuItem value="ethereum">Ethereum</MenuItem>
                  <MenuItem value="solana">Solana</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Max Entries per Address"
                name="maxEntriesPerAddress"
                value={raffleDetails.maxEntriesPerAddress}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                type="number"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Entry Deadline Date"
                type="date"
                name="entryDate"
                value={raffleDetails.entryDate}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Entry Deadline Time"
                type="time"
                name="entryTime"
                value={raffleDetails.entryTime}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="enableAgeGate"
                    checked={raffleDetails.enableAgeGate}
                    onChange={handleInputChange}
                    disabled
                  />
                }
                label="Enable Age Restriction"
              />
            </Grid>

            {raffleDetails.enableAgeGate && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Minimum Age"
                  name="minimumAge"
                  value={raffleDetails.minimumAge}
                  onChange={handleInputChange}
                  required
                  fullWidth
                  margin="normal"
                  type="number"
                  disabled
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={createRaffle}
                fullWidth
                sx={{ marginTop: '1rem' }}
                disabled
              >
                Create Wallet Collector
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <Typography variant="h5" gutterBottom>
        Create an Ethereum or Solana address collector to share with your followers
      </Typography>
      <form noValidate autoComplete="off">
        <Grid container spacing={2}>
          {/* List Name */}
          <Grid item xs={12}>
            <TextField
              label="List Name"
              name="name"
              value={raffleDetails.name}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              error={Boolean(errors.name)}
              helperText={errors.name}
            />
          </Grid>

          {/* Description */}
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={raffleDetails.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
          </Grid>

          {/* List Type */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              margin="normal"
              required
              error={Boolean(errors.raffleType)}
            >
              <InputLabel id="raffle-type-label">List Type</InputLabel>
              <Select
                labelId="raffle-type-label"
                name="raffleType"
                value={raffleDetails.raffleType}
                onChange={handleInputChange}
                label="List Type"
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                <MenuItem value="ethereum">Ethereum</MenuItem>
                <MenuItem value="solana">Solana</MenuItem>
              </Select>
              {errors.raffleType && (
                <FormHelperText>{errors.raffleType}</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* Max Entries per Address */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Max Entries per Address"
              name="maxEntriesPerAddress"
              value={raffleDetails.maxEntriesPerAddress}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
              error={Boolean(errors.maxEntriesPerAddress)}
              helperText={errors.maxEntriesPerAddress}
            />
          </Grid>

          {/* Entry Deadline Date */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry Deadline Date"
              type="date"
              name="entryDate"
              value={raffleDetails.entryDate}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.entryDate)}
              helperText={errors.entryDate}
            />
          </Grid>

          {/* Entry Deadline Time */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Entry Deadline Time"
              type="time"
              name="entryTime"
              value={raffleDetails.entryTime}
              onChange={handleInputChange}
              required
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(errors.entryTime)}
              helperText={errors.entryTime}
            />
          </Grid>

          {/* Age Gate Option */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  name="enableAgeGate"
                  checked={raffleDetails.enableAgeGate}
                  onChange={handleInputChange}
                />
              }
              label="Enable Age Restriction"
            />
          </Grid>

          {raffleDetails.enableAgeGate && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="Minimum Age"
                name="minimumAge"
                value={raffleDetails.minimumAge}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                error={Boolean(errors.minimumAge)}
                helperText={errors.minimumAge}
              />
            </Grid>
          )}

          {/* Create Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={createRaffle}
              fullWidth
              sx={{ marginTop: '0rem' }}
            >
              Create Wallet Collector
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default RaffleCreationForm;



