/**
 * @file  RaffleEntryForm.js
 * @brief Provides a reusable form for submitting raffle entries. Validates entries based on the type of blockchain.
 *        Key Features: Prevents duplicate entries beyond the allowed limit. Collects metadata like IP address and submission device.
 *        Submits data securely to Firestore.
 * @date  2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */

//src/components/RaffleEntryForm.js
import React, { useState, useEffect } from 'react';
import { firestore } from '../firebase';
import {
  collection,
  serverTimestamp,
  doc,
  getDoc,
  query,
  where,
  getCountFromServer,
  runTransaction,
} from 'firebase/firestore';
import { validateAddress } from '../utils/Validation';
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import axios from 'axios';
import { detect } from 'detect-browser';
import { Link } from 'react-router-dom';

const RaffleEntryForm = ({ raffleId, raffleType, user }) => {
  const [address, setAddress] = useState('');
  const [twitterHandle, setTwitterHandle] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [raffleClosed, setRaffleClosed] = useState(false);
  const [maxEntriesPerAddress, setMaxEntriesPerAddress] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [submissionDevice, setSubmissionDevice] = useState(null);
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [minimumAge, setMinimumAge] = useState(null);

  useEffect(() => {
    // Fetch the raffle's closing date, maxEntriesPerAddress, and age gate information
    const checkRaffleStatus = async () => {
      try {
        const raffleRef = doc(firestore, 'raffles', raffleId);
        const raffleSnap = await getDoc(raffleRef);
        if (raffleSnap.exists()) {
          const { entryDeadline, maxEntriesPerAddress, enableAgeGate, minimumAge } = raffleSnap.data();
          setMaxEntriesPerAddress(maxEntriesPerAddress);
          if (enableAgeGate) {
            setMinimumAge(minimumAge);
          }
          if (entryDeadline) {
            const closingDate = new Date(entryDeadline); // UTC date
            const currentDate = new Date(); // Current date/time
            if (closingDate.getTime() < currentDate.getTime()) {
              setRaffleClosed(true);
            }
          }
        }
      } catch (err) {
        console.error('Error checking raffle status:', err);
      }
    };

    checkRaffleStatus();

    // Get the submission device information
    const browser = detect();
    setSubmissionDevice(
      browser ? `${browser.os} - ${browser.name} ${browser.version}` : 'Unknown device'
    );

    // Get the IP address
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIpAddress(response.data.ip);
      } catch (err) {
        console.error('Error fetching IP address:', err);
      }
    };

    fetchIpAddress();
  }, [raffleId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    // Prevent submission if the raffle is closed
    if (raffleClosed) {
      setError('This raffle is closed. You cannot submit an entry.');
      setSubmitting(false);
      return;
    }

    // Validate the age confirmation if age gate is enabled
    if (minimumAge && !ageConfirmed) {
      setError(`You must confirm that you are at least ${minimumAge} years old.`);
      setSubmitting(false);
      return;
    }

    // Validate the address based on the raffle type
    const trimmedAddress = address.trim();
    if (!validateAddress(trimmedAddress, raffleType)) {
      setError(`Invalid ${raffleType} address.`);
      setSubmitting(false);
      return;
    }

    // Validate the Twitter handle
    if (!twitterHandle.startsWith('@')) {
      setError('Please enter a valid Twitter handle, starting with @.');
      setSubmitting(false);
      return;
    }

    try {
      await runTransaction(firestore, async (transaction) => {
        const entriesRef = collection(firestore, 'raffleEntries');
        const q = query(
          entriesRef,
          where('raffleId', '==', raffleId),
          where('address', '==', trimmedAddress)
        );

        // Get count of existing entries
        const snapshot = await getCountFromServer(q);
        const entryCount = snapshot.data().count;

        if (entryCount >= maxEntriesPerAddress) {
          throw new Error(
            `You have reached the maximum number of entries (${maxEntriesPerAddress}) for this raffle.`
          );
        }

        // Add raffle entry to Firestore within the transaction
        const newEntryRef = doc(entriesRef);
        transaction.set(newEntryRef, {
          raffleId,
          address: trimmedAddress,
          twitterHandle: twitterHandle.trim(),
          userId: user ? user.uid : null,
          ipAddress: ipAddress || 'Unavailable',
          submissionDevice: submissionDevice || 'Unknown',
          createdAt: serverTimestamp(),
        });
      });

      setAddress('');
      setTwitterHandle('');
      setAgeConfirmed(false);
      alert('Entry submitted successfully!');
    } catch (error) {
      console.error('Error submitting entry:', error);
      setError(error.message || 'Failed to submit entry. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (raffleClosed) {
    return (
      <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
        <Typography variant="h6" color="error">
          This raffle is closed. You cannot submit an entry.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' } }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: '1.25rem', md: '1.5rem' } }}
            >
              Enter Your {raffleType === 'ethereum' ? 'Ethereum' : 'Solana'} Address:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder={`Enter your ${raffleType} address`}
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={twitterHandle}
              onChange={(e) => setTwitterHandle(e.target.value)}
              placeholder="Enter your Twitter handle (e.g., @username)"
              required
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Grid>

          {/* Age Confirmation */}
          {minimumAge && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ageConfirmed}
                    onChange={(e) => setAgeConfirmed(e.target.checked)}
                    name="ageConfirmed"
                  />
                }
                label={`I confirm that I am at least ${minimumAge} years old.`}
              />
            </Grid>
          )}

          {error && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={submitting}
              fullWidth
            >
              {submitting ? 'Submitting...' : 'Submit Entry'}
            </Button>
          </Grid>

          {/* Disclaimer */}
          <Grid item xs={12}>
            <Typography
              variant="caption"
              align="center"
              display="block"
              sx={{ mt: 2 }}
            >
              By using Wallet Collector, you confirm that you have read and agree to the{' '}
              <Link to="/terms" target="_blank" rel="noopener noreferrer">
                Terms
              </Link>{' '}
              and{' '}
              <Link to="/policy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </Link>.
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default RaffleEntryForm;



