/**
 * @file   about.js
 * @brief  simple explination page for app
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */

import React from 'react';

const AboutPage = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '1rem' }}>About Wallet Collector</h1>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>What is Wallet Collector?</h2>
                <p>Wallet Collector is a powerful tool designed to simplify the collection and management of Ethereum and Solana wallet addresses for raffles, ICOs, and whitelist registrations. Whether you're a creator organizing a raffle/ICO/whitelist or an entrant looking to participate, our app makes the process secure, efficient, and easy to use.</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>How to Use Wallet Collector as a Creator</h2>
<ul>
  <li><b>Sign Up or Log In:</b> Create an account or log in to access your dashboard.</li>
  <li><b>Create a Raffle/ICO List:</b> Use the "Create List" form to set up a new list. Provide the necessary details, such as the list name, rules, and deadline.</li>
  <li><b>Share the Entry Link:</b> Once your list is created, the app generates a unique link. Share this link on your Twitter or other social media platforms to invite entrants.</li>
  <li><b>Manage Entries:</b> View and manage your lists of wallet addresses submitted by entrants in real-time through your dashboard. Export the list as needed.</li>
  <li><b>Search Addresses:</b> Use the built-in search feature to quickly find specific wallet addresses. The search results also display the entrant's Twitter handle, helping you easily reach out for communication or verification.</li>
  <li><b>Open or Closed Status:</b> Easily see which lists are currently accepting entries and which are closed, giving you full control over the availability of your raffles or ICO lists.</li>
</ul>

            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>How to Use Wallet Collector as an Entrant</h2>
                <ul>
                    <li><b>Find the Raffle Link:</b> Look for The List link shared by the creator on their Twitter or other platforms.</li>
                    <li><b>Enter Your Wallet Address:</b> Click the link and enter your Ethereum or Solana wallet address in the provided form. Double-check your submission for accuracy.</li>
                    <li><b>social media connection</b> user must provide twitter handle upon entree for ease of communication between entrant and creator</li>
                    <li><b>Confirmation:</b> After submitting, you’ll receive a confirmation message that your entry has been recorded. Or an error message if it fails.</li>
                    <li><b>Stay Updated:</b> Follow the creator’s announcements for winner notifications and updates.</li>
                </ul>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Why Use Wallet Collector?</h2>
                <p>Wallet Collector is designed with security, simplicity, and efficiency in mind. By leveraging blockchain technology and secure data storage, we ensure the authenticity of wallet addresses and protect user data. Our intuitive interface not only organizes and secures the process for entrants but also saves creators significant time by automating what is traditionally a complex and manual process. This allows creators to focus on running their raffles/ICO's/whitelists while the app handles the tedious tasks of collection and compiling and organizing</p>
            </section>

            <section style={{ marginBottom: '2rem' }}>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Coming Soon: Premium User Accounts</h2>
                <p>We are building premium user accounts to further enhance your experience on Wallet Collector. Premium users will gain access to:
                    <ul>
                        <li>Advanced random selection tools with tunable parameters for picking winners.</li>
                        <li>The ability to share and collaborate on lists with other users.</li>
                        <li>Comprehensive data management and reporting tools designed specifically for creators.</li>
                    </ul>
                    At Wallet Collector, we believe in optimizing Web3 technology for both creators and users. We are actively engaging with our community to shape these premium features based on your needs and feedback. Your input is invaluable as we strive to create the most efficient tools for managing Web3 raffles. Stay tuned for updates, and feel free to reach out to us with your ideas!</p>
            </section>

            <section>
                <h2 style={{ fontSize: '1.5rem', marginBottom: '0.5rem' }}>Need Help?</h2>
                <p>If you have any questions or need assistance, contact us at walletcollectormain@gmail.com</p>
            </section>
        </div>
    );
};

export default AboutPage;

