/**
 * @file   footer.js
 * @brief  creates the page footer with links to socials(X) and privacy/terms pages
 * @date   2024-04-27
 * 
 * © 2024 Bissonette Data Solutions Corp. All Rights Reserved.
 * 
 * LICENSE:
 * This file is part of the WalletCollector project.
 * Unauthorized copying, modification, distribution, or any other use
 * is strictly prohibited without prior written consent from Bissonette Data Solutions Corp.
 */


import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: 'primary.main',
        color: 'white',
        py: 3,
        mt: 'auto',
        textAlign: 'center',
        width: '100%',
        position: 'relative',
        bottom: 0,
      }}
    >
      <Typography variant="body2" sx={{ mb: 2 }}>
        &copy; {new Date().getFullYear()} Bissonette Data Solutions Corp. All rights reserved.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>  
        <IconButton
          href="https://www.twitter.com/0xWalletCollect"
          target="_blank"
          rel="noopener noreferrer"
          color="inherit"
          aria-label="Twitter"
        >
          <TwitterIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
        <Link to="/about" style={{ color: 'inherit', textDecoration: 'none' }}>
          About
        </Link>
        <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
          Terms of Service
        </Link>
        <Link to="/policy" style={{ color: 'inherit', textDecoration: 'none' }}>
          Privacy Policy
        </Link>
        <Link to="/contactus" style={{ color: 'inherit', textDecoration: 'none' }}>
          Contact Us
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
